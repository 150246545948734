:root {
  --red-color: #e21313;
  --gray-color: #f0f2f5;
  --dark-color: #e4e4e4;
  --white-color: #ffffff;
  --green-color: #015801;
  --orange-color: #d04702;
  --secondary-color: #fafafa;
  --gestor-color: #0053a6;
}
.col-impressora .ant-card {
  min-height: 680px;
  background-image: url('../../assets/i-print-impressora.png');
  background-position: bottom center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 563px) {
  .col-impressora .ant-card {
    min-height: 375px;
  }
}
.col-impressora .ant-card .ant-card-body {
  left: 0;
  top: 28%;
  z-index: 0;
  width: 100%;
  text-align: center;
  position: absolute;
}
@media screen and (max-width: 563px) {
  .col-impressora .ant-card .ant-card-body {
    top: 15px;
  }
}
.col-impressora .ant-card .ant-card-body .ant-card-meta-title {
  font-size: x-large;
}
.col-impressora .ant-card .ant-card-body .ant-card-meta-description {
  font-size: larger;
}
.col-impressora .ant-card .ant-card-head {
  border: 0;
}
.col-impressora .ant-card .ant-card-extra {
  top: 0;
  left: 0;
  border: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
}
.col-impressora.etiqueta .ant-card {
  background-image: url('../../assets/i-etiqueta.png');
}
@media screen and (max-width: 1024px) {
  .col-impressora.etiqueta button {
    margin-top: 30px;
  }
}
@media screen and (max-width: 563px) {
  .col-impressora.etiqueta button {
    margin-top: -45px;
  }
}
