:root {
  --red-color: #e21313;
  --gray-color: #f0f2f5;
  --dark-color: #e4e4e4;
  --white-color: #ffffff;
  --green-color: #015801;
  --orange-color: #d04702;
  --secondary-color: #fafafa;
  --gestor-color: #0053a6;
}
.col-modulo {
  left: 0;
  top: 80px;
  z-index: 1;
  width: 100%;
  position: fixed;
  background-color: var(--white-color);
}
.col-modulo .rowPanel {
  padding-top: 15px;
  overflow-x: hidden;
  height: calc(100vh - 122px);
  margin-bottom: 0 !important;
  margin-top: -16px !important;
}
.col-modulo span {
  font-size: 14px;
}
.col-modulo .ant-col a {
  white-space: pre-wrap;
}
.col-modulo .ant-col a img {
  width: 82px;
  opacity: 0.8;
  align-self: center;
  margin-bottom: 10px;
}
.col-modulo .ant-col a b {
  line-height: 15px;
}
