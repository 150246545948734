:root {
  --red-color: #e21313;
  --gray-color: #f0f2f5;
  --dark-color: #e4e4e4;
  --white-color: #ffffff;
  --green-color: #015801;
  --orange-color: #d04702;
  --secondary-color: #fafafa;
  --gestor-color: #0053a6;
}
.col-busca {
  left: 0;
  top: 120px;
  z-index: 1;
  width: 100%;
  position: fixed;
  overflow: hidden;
  height: calc(100% - 120px);
}
.col-busca .ant-card .ant-badge-count,
.col-busca .ant-card .ant-badge-dot,
.col-busca .ant-card .ant-badge .ant-scroll-number-custom-component {
  top: 0;
  right: -10px;
}
.col-busca .ant-card .ant-badge-count span,
.col-busca .ant-card .ant-badge-dot span,
.col-busca .ant-card .ant-badge .ant-scroll-number-custom-component span {
  font-size: 12px;
}
.col-busca .ant-tabs-nav-wrap {
  padding-top: 5px;
}
.col-busca .ant-tabs-tab .ant-badge-count,
.col-busca .ant-tabs-tab .ant-badge-dot,
.col-busca .ant-tabs-tab .ant-badge .ant-scroll-number-custom-component {
  top: 0;
  right: 0;
  margin-left: 8px;
  position: relative;
}
.col-busca .slick-prev:before,
.col-busca .slick-next:before {
  opacity: 1;
  color: #868686;
}
.col-busca .p-5 {
  padding: 5px;
}
.col-busca .tabela {
  height: 100%;
}
.col-busca .tabela > .ant-table-wrapper {
  margin-top: 0;
}
.col-busca .rowPanel {
  overflow-x: hidden;
  padding-bottom: 15px;
  height: calc(100% - 88px);
}
.col-busca .ant-tabs-tabpane {
  height: 100%;
}
.col-busca .ant-tabs-content-holder {
  height: 100vh;
}
.col-busca .ant-tabs-content {
  height: 100%;
}
