/************* CUSTOM ***************/
/* Chrome, Safari, Edge, Opera */
input[type="date"]::-webkit-calendar-picker-indicator {
    left: 0;
    margin: 0;
    .cursor-pointer();
    position: absolute;
    padding: 1px 15px 0 5px;
}

.cursor-pointer {
    cursor: pointer;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.f-14 {
    font-size: 14px !important;
}

.f-16 {
    font-size: 16px !important;
}

.f-18 {
    font-size: 18px !important;
}

.f-21 {
    font-size: 21px !important;
}

.f-25 {
    font-size: 25px !important;
}

.f-30 {
    font-size: 30px !important;
}

.f-50 {
    font-size: 50px !important;
}

.p-0 {
    padding: 0 !important;
}

.p-b-0 {
    padding-bottom: 0 !important;
}

.p-5 {
    padding: 5px;
}

.p-5-8 {
    padding: 5px 8px;
}

.p-6 {
    padding: 6px;
}

.p-8 {
    padding: 8px;
}

.pad-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.p-t-5 {
    padding-top: 5px;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-b-15 {
    padding-bottom: 15px;
}

.p-b-52 {
    padding-bottom: 52px !important;
}

.p-b-75 {
    padding-bottom: 75px !important;
}

.p-2-7 {
    padding: 2px 7px;
}

.p-t-b-8 {
    padding: 8px 0 !important;
}

.p-t-b-5 {
    padding: 5px 0 !important;
}

.p-r-l-8 {
    padding: 0 8px;
}

.p-4-5 {
    padding: 4px 5px !important;
}

.p-l-0 {
    padding-left: 0px !important;
}

.p-l-5 {
    padding-left: 5px !important;
}

.p-l-25 {
    padding-left: 25px !important;
}

.m-0 {
    margin: 0 !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-2 {
    margin: 2px !important;
}

.m-t-4 {
    margin-top: 4px;
}

.m-t-3 {
    margin-top: 3px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-8 {
    margin-top: 8px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-12 {
    margin-top: 12px;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-t-16 {
    margin-top: 16px !important;
}

.m-t-18 {
    margin-top: 18px !important;
}

.m-t-19 {
    margin-top: 19px !important;
}

.m-t-21 {
    margin-top: 21px !important;
}

.m-t-28 {
    margin-top: 28px !important;
}

.m-t-32 {
    margin-top: 32px;
}

.m-t-55 {
    margin-top: 55px;
}

.m-t-b-5 {
    margin: 5px 0 !important;
}

.m-t-b-10 {
    margin: 10px 0;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-8 {
    margin-bottom: 8px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-l-5 {
    margin-left: 5px !important;
}

.m-b-16 {
    margin-bottom: 16px;
}

.m-r-0 {
    margin-right: 0 !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.w-100 {
    width: 100% !important;

    .ant-card-extra {
        width: 100% !important;
    }
}

.over-div {
    overflow: hidden;
}

.line-height {
    line-height: normal;
}

.txt-overflow {
    .over-div();
    white-space: nowrap;
    text-overflow: ellipsis;
}

.b-radius-100 {
    border-radius: 100%;
}

.bg-white {
    background-color: var(--white-color);
}

.b-radius-2 {
    border-radius: 2px !important;
}

.b-0 {
    border: 0 !important;
}

.b-1 {
    border: 1px solid @border-color-base;
}

.b-t {
    border-top: 1px solid @border-color-base;
}

.b-b-1 {
    border-bottom: 1px solid @border-color-base;
}

.b-t-r-l-1 {
    border: 1px solid @border-color-base;
    border-bottom: 0;
}

.f-500 {
    font-weight: 500;
}

.hg-mobile {
    height: 32px !important;
}

.hg-100 {
    height: 100%;
}

button {
    .cursor-pointer();

    span {
        .cursor-pointer();
    }
}

label {
    margin-bottom: 2px;
    color: @opacity-85;
    display: inline-block;
}

.img {
    >img {
        .w-100();
        max-width: 80%;

        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }
}

.yellow {
    background: #ffffab;
}

.green {
    background: #baf39e;
}


.h3-carousel {
    height: 250px;
    color: #ffff;
    background: #364d79;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-carousel {
    width: 100%;
    height: auto;
}

.divimg {
    display: flex;
    height: calc(100vh - 200px);
    width: 70vh;
    max-width: inherit !important;
}

.imageZoom img {
    width: 70vh;
}
.imageZoomCat img {
        width: 100vh;
        height: 100%;
}

.divimgGrupoSubCat {
    display: flex;
    // margin-top: 18px;
    div {
        height: calc(66vh - 124px);
    }
}

.divSubgrupos
{
    width: 100%;
    display: block;
}

.exibeImg {
    width: inherit !important;
    height: inherit !important;
}

.tabela {
    &.hgt {
        .ant-table-body {
            height: calc(100vh - 280px);
        }

        .ant-pagination {
            margin-top: -30px;
            position: relative;
        }

        .ant-table-footer {
            padding: 0 !important;
        }

        table {
            table-layout: inherit !important;
        }
    }

    &.hgtGrupoCat {
        .ant-table-body {
            //display: flex;
            height: calc(66vh - 152px);
            max-height: inherit !important;
            overflow-y: inherit !important;
            overflow-x: hidden;
        }
    }

    &.hgtGrupos {
        display: flex;
        width: 100%;
        margin-top: 15px;
        .ant-table-body {
            height: calc(38vh - 154px) !important;
        }
        div {
            .ant-table-body {
                height: calc(38vh - 154px) !important;
            }
        }
    }
}

.btnPesquisa {
    margin: auto;
    margin-top: 18px;
    
    button {
        background: #364d79;
        color: #FFFF;
        border-radius: 9px;
        width: 130px;
        height: 36px;
        font-size: 14px;
    }
    button:hover{
        background: #21304b;
        color: #FFFF;
    }

}

body {
    .over-div();

    .ant-input[disabled] {
        background-color: #f7f7f7;
    }

    .ant-alert-info {
        background-color: #e6f7ff;
        border: 1px solid #91d5ff;
    }

    .ant-drawer {
        .ant-tag-green {
            color: var(--white-color);
            background: var(--green-color);
            border-color: var(--green-color);
        }

        .ant-tag-processing {
            color: var(--white-color);
            background: @primary-color;
            border-color: @primary-color;
        }

        .ant-drawer-header {
            color: var(--white-color);
            border-radius: 0 !important;
            border-bottom: 0 !important;
            padding: 8px 15px !important;
            background: @primary-color !important;

            .ant-drawer-title {
                .text-uppercase();
                color: var(--white-color);
            }

            .ant-drawer-close {
                top: 0;
                right: -12px;
                position: absolute;
                color: var(--white-color);
                padding: 12px 21px !important;
            }
        }

        .ant-drawer-body {
            .p-15() !important;
        }

        .ant-drawer-footer {
            padding: 5px 16px;
        }
    }

    .ant-checkbox {
        +span {
            padding: 0 4px;
        }
    }

    .ant-tag-red {
        color: white;
        background: #cf1322;
        border-color: #cf1322;
    }

    .ant-breadcrumb-separator {
        margin: 0 5px !important;
    }

    .ant-upload-list-picture-card {
        .ant-upload-list-item-info {
            &::before {
                top: 0;
                left: 0;
            }
        }
    }

    .ant-upload-list-picture .ant-upload-list-item-error,
    .ant-upload-list-picture-card .ant-upload-list-item-error {
        border-color: @border-color-base;
    }

    .ant-descriptions-small {
        .ant-descriptions-row {
            >th {
                .p-b-0();
            }
        }
    }

    .ant-descriptions-small {
        .ant-descriptions-row {
            >td {
                .p-b-0();
            }
        }
    }

    .ant-input-affix-wrapper-sm {
        padding: 0 7px;

        .ant-input {
            &.ant-input-sm {
                height: 22px;
            }
        }
    }

    .ant-form-small {
        height: inherit;

        .ant-form-item-label {
            .p-0();

            >label {
                height: inherit;
                line-height: 11px;
            }
        }
    }

    .ant-card-bordered {
        border: 1px solid @border-color-base;
    }

    .ant-input {
        &.ant-input-sm {
            .p-2-7();
            height: 24px;
        }
    }

    .form-data {
        .ant-input {
            &.ant-input-sm {
                height: 24px;
                line-height: 24px;
                padding: 2px 0 2px 22px;

                &.ant-input-disabled {
                    padding: 2px 0 2px 7px;
                }
            }
        }
    }

    #manutencaoCondicional_horaagendada {
        padding: 2px 3px 2px 6px;
    }

    .ant-input[disabled],
    .ant-input-number-disabled {
        color: rgba(0, 0, 0, 1) !important;
    }

    .ant-collapse-content-active {
        .m-t-8();
    }

    .b-col {
        .ant-collapse-content-active {
            .m-b-16();
        }
    }

    .w-50 {
        width: 50px !important;

        b {
            .w-100();
        }
    }

    .ant-modal {
        @media screen and (max-width: 990px) {
            width: 95% !important;

            &.ant-modal-confirm {
                width: 320px !important;
            }
        }

        .ant-modal-content {
            -webkit-border-top-left-radius: 5px;
            -webkit-border-top-right-radius: 5px;
            -moz-border-radius-topleft: 5px;
            -moz-border-radius-topright: 5px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            .ant-modal-close {
                color: var(--white-color);

                .ant-modal-close-x {
                    .h-auto();
                    width: auto;
                    .line-height();
                    padding: 10px 15px !important;
                }
            }

            .ant-modal-header {
                color: var(--white-color);
                padding: 8px 15px !important;
                background-color: @primary-color !important;

                .ant-modal-title {
                    .text-uppercase();
                    color: var(--white-color);
                }
            }

            .ant-modal-body {
                .p-15();

                .tabela {
                    .ant-table-tbody {
                        .ant-tag {
                            width: auto !important;

                            b {
                                width: auto !important;
                            }

                            &.w-100 {
                                .w-100();

                                b {
                                    .w-100();
                                }
                            }

                            &.w-75 {
                                .w-75();

                                b {
                                    .w-100();
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ant-tag-cyan {
        border-color: #14a3b3;
        color: var(--white-color);
        background-color: #14a3b3;
    }

    .ant-drawer-mask {
        width: 200%;
    }

    .ant-form-item-explain,
    .ant-form-item-extra {
        padding-top: 0;
        font-size: 10px;
        min-height: inherit;
    }

    .ant-btn>.anticon+span,
    .tabela.ant-btn>span+.anticon {
        .m-l-5();
    }

    .ant-modal-confirm-body {
        flex: 1;
        display: flex;
        .text-center();
        flex-direction: column;
        justify-content: center;

        >.anticon {
            .m-r-0();
            .m-b-5();
            float: inherit;
            font-size: 25px;
        }
    }

    .ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
        margin-left: 0;
    }

    .ant-modal-confirm {
        .ant-modal-confirm-btns {
            .m-t-10();
            display: flex;
            float: inherit;
            justify-items: center;
            justify-content: center;
        }
    }

    .ant-modal-mask,
    .ant-drawer-mask {
        background-color: rgba(0, 0, 0, 0.70);
    }

    .w-color-circle {
        .hide();
        top: 12px;
        left: 5px;
        z-index: 5;
        position: absolute !important;

        >div {
            width: 24px !important;
            height: 24px !important;
            padding: 2px !important;
            margin: 0 2px !important;
            box-shadow: inherit !important;
            border: 1px solid var(--white-color) !important;

            div {
                background-color: rgb(0 0 0 / 30%) !important;
            }
        }
    }

    .ant-collapse-content-box {
        .ant-empty-normal {
            .bg-white();
            margin: -5px;
            padding: 10px 0;
        }
    }

    table {
        .ant-table-placeholder {
            .ant-table-expanded-row-fixed {
                .w-100();
                .bg-white();
            }
        }

        .ant-table-tbody {
            .ant-table-cell-fix-left {
                .ant-tag {
                    .w-100();

                    b {
                        .w-100();
                    }

                    &.w-75 {
                        .w-75();
                    }
                }
            }

            .ant-tag {
                .m-r-0();
                .text-center();
                padding: 0 3px;
                display: inline-flex;

                &.m-r-5 {
                    .m-r-5();
                }

                b {
                    .f-500();
                    .txt-overflow();
                }

                &.ant-tag-checkable {
                    width: auto !important;

                    b {
                        width: auto !important;
                    }
                }

                &.w-100 {
                    b {
                        .w-100();
                        .text-center();
                    }
                }
            }

            >tr {
                >td {
                    background-color: #f9f9f9;

                    &.ant-table-cell {
                        .p-4-5();
                    }
                }

                &:nth-child(even) {
                    >td {
                        background-color: white;
                    }
                }

                label {
                    .h-auto();
                }

                .ant-form-item-label {
                    .p-0();
                }

                &.ant-table-row {
                    &:hover {
                        background-color: #f9f9f9 !important;

                        >td {
                            background-color: #f9f9f9 !important;
                        }
                    }
                }

                &.ant-table-row-selected {
                    &:hover {
                        >td {
                            background-color: #dbdbdb;
                        }
                    }

                    >td {
                        background-color: #d6d6d6 !important;
                        border-color: rgba(0, 0, 0, 0.1) !important;
                    }
                }
            }
        }
    }

    .ant-table-small {
        .ant-table-selection-column {
            width: auto !important;
            min-width: auto !important;
        }
    }

    .ant-input,
    input,
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
        .f-500();
    }

    .ant-tabs-nav-list {
        >.ant-tabs-tab {
            padding: 5px 10px !important;
            margin: 0 1px 0 0 !important;
            border-color: @border-color-base !important;

            &.ant-tabs-tab-active {
                border-bottom-color: transparent !important;
            }
        }
    }

    .ant-select,
    .ant-input-number {
        .w-100();
    }

    .ant-input-number-input {
        .text-right();
    }

    .ant-picker {
        .w-100();
    }

    .ant-form-item {
        .m-b-5();

        .anticon {
            .m-l-5();
        }

        .ant-input-search-button {
            .anticon {
                margin-left: 0 !important;
            }
        }
    }

    .ant-menu-vertical .ant-menu-item,
    .ant-menu-vertical-left .ant-menu-item,
    .ant-menu-vertical-right .ant-menu-item,
    .ant-menu-inline .ant-menu-item,
    .ant-menu-vertical .ant-menu-submenu-title,
    .ant-menu-vertical-left .ant-menu-submenu-title,
    .ant-menu-vertical-right .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-submenu-title {
        .m-t-0();
        .m-b-0();
    }

    .ant-tabs-tab {
        margin: 0 12px 0 12px !important;
    }

    .ant-radio-wrapper {
        white-space: normal !important;
    }

    .avatar-uploader {
        img {
            .p-5();
            .w-100();
        }
    }

    .ant-descriptions-item-content {
        b {
            font-weight: bold;
        }
    }

    .ant-descriptions-row {
        >th {
            .p-b-0();
        }

        >td {
            .p-b-0();
        }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: var(--secondary-color) !important;
    }

    .ant-card-bordered {
        border-color: @border-color-base !important;
    }

    .ant-form-item-control-input-content {
        b {
            .w-100();
            .p-2-7();
            float: left;
            height: 24px;
            .b-radius-2();
            .text-right();
            border: 1px solid @border-color-base;

            &.text-left {
                .text-left();
            }

            @media screen and (max-width: 992px) {
                height: 32px;
                padding: 6px 7px;
            }
        }

        .in-form {
            .w-100();
            .p-2-7();
            float: left;
            height: 24px;
            .b-radius-2();
            border: 1px solid @border-color-base;
        }

        .MuiFormControl-root {
            .w-100();

            .MuiInput-formControl {
                .p-0();
                .m-0();
                .w-100();
                .f-500();
                .b-radius-2();
                .p-relative();
                font-size: 12px;
                color: #3c3c3c;
                list-style: none;
                align-items: center;
                display: inline-flex;
                box-sizing: border-box;
                background-color: var(--white-color);
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
                font-variant: tabular-nums;
                transition: border 0.3s, box-shadow 0.3s;

                &.Mui-focused {
                    box-shadow: 0 0 2px 2px #ccdced;

                    .MuiInputBase-input {
                        border: 1px solid #1d6db3;
                    }
                }
            }

            .MuiInputBase-input {
                height: 19px;
                .b-radius-2();
                .text-uppercase();
                padding: 1px 0 1px 5px;
                border: 1px solid @border-color-base;

                &:hover {
                    border: 1px solid #1d6db3;
                }
            }

            .MuiInput-underline {

                &:before,
                &:after {
                    display: none;
                }

                &:hover:not(.Mui-disabled) {
                    &:before {
                        border-bottom: 0;
                    }
                }

                &:hover {
                    .b-0();
                }
            }
        }
    }

    .ant-false-table {
        .f-500();
        color: @opacity-85;
        border-bottom: 1px solid var(--white-secondary-color);
    }

    .ant-steps-item-container {
        .w-100();
        .text-center();
    }

    .ant-slider {
        margin: 5px 6px;
    }

    @media screen and (max-width: 600px) {
        .ant-divider-horizontal.ant-divider-with-text-left {
            &::after {
                width: 10%;
            }
        }
    }
}

.opc {
    .ant-drawer-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
}

.eti {
    .ant-drawer-mask {
        background-color: rgba(0, 0, 0, 0.35);
    }
}

.link-pag {
    a {
        .anticon {
            margin-right: 5px;
        }
    }

    .ant-divider {
        .m-b-0();
        margin-top: 2px;
    }

    button {
        .p-0();
        .text-right();
    }
}

.ant-input-number-handler-wrap {
    .hide();
}

.menuNotificacoes {
    .w-100();
    .text-center();
    bottom: 0 !important;
    position: absolute !important;
}

.vh100 {
    .over-div();
    height: 100vh !important;

    .ant-tag-green {
        color: var(--white-color);
        border-color: var(--green-color);
        background-color: var(--green-color);
    }

    .ant-tag-processing {
        color: var(--white-color);
        border-color: @primary-color;
        background-color: @primary-color;
    }
}

.f-right {
    float: right;
}

.p-10 {
    .pad-10();

    .ant-input-sm {
        .p-2-7();
    }

    .ant-select-multiple {
        &.ant-select-sm {
            .ant-select-selection-item {
                .m-b-0();
                .m-t-0();
                .h-auto();
                .line-height();
                margin-inline-end: 2px;
                padding-inline-end: 4px;
                padding-inline-start: 4px;
            }
        }

        .ant-select-selection-item-content {
            .m-r-0();
        }

        .ant-select-selection-item-remove {
            >.anticon {
                vertical-align: sub;
            }

            .anticon-close {
                margin-left: 2px;
            }
        }

        .ant-select-selector {
            padding-right: 7px;
        }
    }
}

.col-layout {
    .p-5-8();
}

.p-85 {
    padding-bottom: 85px;
}

.footer-recebimento {
    right: 0;
    bottom: 0;
    z-index: 2;
    .bg-white();
    position: fixed;
    width: calc(100% - 201px);

    @media screen and (max-width: 990px) {
        width: calc(100% - 0px);
    }

    @media screen and (max-width: 790px), screen and(max-height: 760px) {
        .w-100();
        .m-t-16();
        .p-relative();
        padding: 10px 0 0 !important;
    }
}

.pad-page-detalhe {
    .p-b-75();

    @media screen and (max-width: 790px) {
        .p-b-0();
        .m-t-16();
    }
}

.footer-parcelas-recebimento {
    right: 0;
    z-index: 2;
    bottom: 48px;
    position: absolute;
    width: calc(100% + 16px);
    background-color: var(--white-color);

    @media screen and (max-width: 690px) {
        .p-0();
        .w-100();
        .m-t-16();
        bottom: auto;
        .p-relative();
    }
}

.c-primary {
    color: @primary-color;
}

.h-auto {
    height: auto !important;
}

.tabela-fluxo {
    color: @opacity-85;
    border: 1px solid @border-color-base;

    .t-body {
        div {
            .p-5();
        }
    }

    .row {
        .p-0();
        .b-t();

        .col {
            div {
                .p-0();
            }
        }
    }

    .t-head {
        align-items: center;
        display: flex;
    }

    .cabecalho {
        height: 60px;
    }

    .col-1 {
        width: 25%;
        float: left;

        div {
            .p-5();
        }
    }

    .ant-col-3 {
        .ant-row {
            .p-0();

            .ant-col-12 {
                .p-0();
            }
        }

        &.col {
            .p-t-b-5 ();
        }
    }

    .scroll {
        width: 75%;
        float: left;
        display: flex;
        overflow: auto;
        white-space: nowrap;

        div {
            .p-5();
        }

        .cabecalho {
            div {
                padding: 1px !important;
            }
        }
    }
}

.c-red {
    color: var(--red-color) !important;
}

.c-green {
    color: var(--green-color);
}

.p-relative {
    position: relative;
}

.hide {
    display: none !important;
}

.hg-110 {
    height: 110px !important;
}

.site-layout {
    .p-r-l-8();
    .bg-white();
    border-left: 1px solid @border-color-base;

    >.ant-layout-content {
        .bg-white();
    }

    .ant-alert-info {
        background-color: #e6f7ff;
        border: 1px solid #91d5ff;
    }
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
    overflow-x: hidden;
}

.colDet {
    top: 0;
    left: 0;
    .w-100();
    z-index: 1;
    height: 32px;
    position: absolute;
}

.d-info-gray {
    .pad-10();
    background-color: var(--gray-color);
    border: 1px solid @border-color-base;
}

.b-color-primary {
    background-color: @primary-color !important;

    button {
        height: 32px;
        padding: 5.6px 15px;
        border-color: @primary-color;
        color: var(--white-color) !important;
        background-color: @primary-color !important;
    }
}

.c-white {
    color: var(--white-color) !important;
}

.b-color-red {
    .b-radius-2();
    background-color: var(--red-color) !important;

    button {
        height: 32px;
        padding: 5.6px 15px;
        border-color: #dd0505;
        color: var(--white-color) !important;
        background-color: var(--red-color) !important;

        &:hover,
        &:focus {
            border-color: #dd0505;
        }
    }
}

.b-color-gray {
    .b-radius-2();
    background-color: var(--gray-color) !important;

    button {
        height: 32px;
        padding: 5.6px 15px;
        border-color: @border-color-base;
        background-color: var(--gray-color) !important;
    }
}

.b-color-secondary {
    background-color: var(--secondary-color) !important;
}

.crm-card {
    .min-hg {
        .ant-card-body {
            display: flex;
            min-height: 95px;
            flex-direction: column;
            vertical-align: middle;
            justify-content: center;
        }
    }

    .ant-card-head {
        .m-b-0();
        padding: 0 10px;
        min-height: inherit;
        background-color: var(--gray-color) !important;

        .ant-card-head-title {
            .p-t-b-5();
            .text-center();
            .text-uppercase();
            text-overflow: ellipsis;
        }
    }

    .ant-card-body {
        .p-15();
    }

    .ant-btn-link {
        .p-0();
    }

    .col-infos {
        .ant-card-head {
            .p-r-l-8();
            min-height: auto;
        }

        .ant-card-extra {
            padding: 8px 0 5px;
        }

        .ant-card-body {
            .p-0();

            .ant-table-pagination.ant-pagination {
                margin: 8px 0 !important;
            }

            .ant-table-container {
                .b-0();
            }
        }
    }

    .col-cheque {
        .ant-card-body {
            min-height: 111px;
        }
    }

    .ant-col-8 {
        .text-right();
    }
}

.card-crm {
    .ant-card-head {
        border-bottom: 0;
        min-height: auto !important;
    }

    .ant-card-extra {
        .p-0();
        top: 14px;
        right: 15px;
        position: absolute;

        @media screen and (max-width: 992px) {
            top: 10px;
            right: 10px;

            .ant-btn-sm {
                .p-0;
                width: 35px;
            }
        }

    }

    .select {
        top: 14px;
        left: 12px;
        z-index: 1;
        position: absolute;
        width: calc(100% - 65px);
    }
}

.card-dash {
    h4 {
        min-height: 51px;

        @media screen and (max-width: 690px) {
            min-height: auto;
        }
    }
}

.img-assinatura {
    .w-100();
    padding: 0 15px 5px 15px;
    border-bottom: 1px solid @border-color-base;
}

.c-orange-sec {
    background-color: var(--orange-color);
}

.pages-col {
    .ant-divider-horizontal {
        &.ant-divider-with-text {
            .m-0();
        }
    }

    h3 {
        &.ant-typography {
            .m-b-0();
        }
    }

    .ant-typography {
        h3 {
            .m-b-0();
        }
    }

    .ant-divider-horizontal {
        margin: 5px;
    }

    .ant-descriptions-row>th,
    .ant-descriptions-row>td {
        .p-b-0();
    }
}

.page {
    .ant-select-multiple {
        &.ant-select-sm {
            .ant-select-selection-item {
                .line-height();
                height: inherit;
            }
        }
    }

    .ant-collapse {
        &.topDif {
            .ant-collapse-extra {
                .m-t-0();
            }
        }
    }
}

#formManutencaoCaixa {
    .page {
        .ant-collapse {
            &.topDif {
                .ant-collapse-extra {
                    margin-top: -25px;

                    @media screen and (max-width: 990px) {
                        .m-t-3();
                    }

                    @media screen and (max-width: 572px) {
                        .w-100();
                    }
                }
            }
        }
    }
}

.page-In {
    .ant-collapse-ghost {
        >.ant-collapse-item {
            >.ant-collapse-content {
                >.ant-collapse-content-box {
                    .p-b-0();
                    border-top: 0;
                    padding-top: 0;
                }
            }
        }
    }

    .ant-collapse-content {
        >.ant-collapse-content-box {
            .p-0();
            max-height: 450px;
            overflow-x: hidden;
        }
    }

    .ant-collapse {
        >.ant-collapse-item {
            >.ant-collapse-header {
                .ant-collapse-extra {
                    float: left;
                }
            }
        }

        .ant-collapse-extra {
            .m-b-0();
            .m-t-0();
            border-top: 0;
            cursor: default;
            padding: 5px 10px;

            .ant-checkbox {
                z-index: 20;
                .p-relative();
            }

            .ant-form-item {
                .m-b-0() !important;
            }
        }

        >.ant-collapse-item {
            .m-t-16();
            .p-relative();

            >.ant-collapse-header {
                .p-0();
                display: flow-root;
                border: 1px solid @border-color-base;
            }
        }
    }

    .ant-collapse-icon-position-right {
        >.ant-collapse-item {
            >.ant-collapse-header {
                .ant-collapse-arrow {
                    right: 0;
                    .pad-10();
                    top: 14px;
                    z-index: 20;
                }
            }
        }
    }

    .col-inu {
        .ant-collapse-extra {
            padding: 0 10px;
        }
    }
}

.i-Position {
    z-index: 1;
    margin-top: -30px;
}

.ant-dropdown-menu {
    .ant-btn-sm {
        .w-100();
        .text-right();
        padding: 2px 0;
        height: inherit;

        a {
            color: #3c3c3c;
        }
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        .line-height();
        padding: 3px 12px;
    }
}

.tabela {
    .ant-divider-horizontal {
        &.ant-divider-with-text {
            border-top-color: @border-color-base;
        }
    }



    .ant-table-tbody {
        >tr {
            >td {
                .b-b-1();
            }
        }

        .green {
            td {
                background-color: #baf39e;

                &.ant-table-cell-fix-left,
                &.ant-table-cell-fix-right {
                    background-color: #baf39e;
                }
            }
        }

        .yellow {
            td {
                background: #ffffab;

                &.ant-table-cell-fix-left,
                &.ant-table-cell-fix-right {
                    background-color: #ffffab;
                }
            }
        }

        .blue {
            td {
                background: #4b7fc9;

                &.ant-table-cell-fix-left,
                &.ant-table-cell-fix-right {
                    background-color: #4b7fc9;
                }
            }
        }
    }

    .ant-table-thead {
        >tr {
            >th {
                .b-b-1();
            }
        }
    }

    &.modalTable {
        .ant-table-expanded-row {
            .ant-table {
                .b-t();
                .b-b-1();

                &.ant-table-small {
                    margin: 4px 0 !important;
                }
            }

            .ant-spin-nested-loading {
                padding: 4px 0;
            }

            .ant-table-tbody {
                >tr {
                    >td.ant-table-cell {
                        .p-0();

                        >div {
                            padding: 4px;
                        }
                    }
                }
            }

            .ant-table-thead {
                display: none;
            }
        }
    }

    &.hg-lan {
        height: calc(100vh - 200px);

        @media screen and (max-width: 992px) {
            height: calc(100vh - 235px);
        }
    }

    .ant-descriptions-header {
        .m-0();
    }

    .ant-descriptions-small .ant-descriptions-row>th,
    .ant-descriptions-small .ant-descriptions-row>td {
        .m-0();
    }

    &.check-list {
        .ant-table-tbody {
            >tr {
                >td.ant-table-cell {
                    .p-0();
                }
            }
        }
    }

    .ant-table-small {
        .ant-table-thead {
            >tr {
                >th {
                    background-color: #f1f1f1;

                    &.ant-table-cell {
                        .p-4-5();
                    }
                }
            }
        }
    }

    .ant-alert {
        padding: 2px 8px;
    }

    .ant-table-footer {
        padding: 4px 5px 0 5px !important;
    }

    .ant-divider {
        margin: 5px 0 12px 0;
    }

    .ant-collapse {
        >.ant-collapse-item {
            >.ant-collapse-header {
                .ant-collapse-extra {
                    float: left;
                }
            }
        }
    }

    &.mt-zero {
        >.ant-table-wrapper {
            .m-t-0();
        }
    }

    &.mt-8 {
        >.ant-table-wrapper {
            .m-t-8();
        }
    }

    &.mt-dif {
        >.ant-table-wrapper {
            .m-t-16();
        }
    }

    // >.ant-table-wrapper {
    //     margin-top: 18px;
    // }

    table {
        .ant-table-expanded-row-fixed {
            .p-8();
        }

        span {
            .over-div();
            max-width: 100%;
            text-overflow: ellipsis;

            &.ant-divider-inner-text {
                .w-100();
            }

            &.ant-select-arrow {
                overflow: inherit !important;
            }
        }

        .ant-table-cell {
            .txt-overflow();

            &.ant-table-selection-column {
                overflow: inherit;
            }

            >div {
                .txt-overflow();
                // justify-items: center;  COMENTADO POIS ESTAVA CENTRALIZANDO COLUNAS NAS TABELAS

                @media screen and (max-width: 690px) {
                    white-space: normal;
                }

                &.white-space-normal {
                    white-space: normal;
                }

                div {
                    white-space: normal;
                }

                &.ant-table-selection {
                    overflow: inherit;

                    span {
                        overflow: inherit;
                    }
                }
            }

            .ant-form-item {
                .m-b-0() !important;
            }
        }

        .ant-table-cell-content {
            div {
                .over-div();
                text-overflow: ellipsis;
            }
        }

        .ant-table-cell-ellipsis {
            div {
                .over-div();
                text-overflow: ellipsis;
            }
        }

        .ant-btn-sm {
            line-height: 12px;

            .ant-btn-icon-only.ant-btn-sm>* {
                font-size: 13px;
            }
        }
    }

    .ant-tag-processing {
        color: var(--white-color);
        background: @primary-color;
        border-color: @primary-color;
    }

    .ant-tag-green {
        color: var(--white-color);
        background: var(--green-color);
        border-color: var(--green-color);
    }

    .ant-table-footer {
        background-color: var(--gray-color);
    }

    &.expandirNfe {
        .tabelaItens {
            .ant-table-row-level-0 {
                .ant-table-cell {
                    background-color: #d3d3d3 !important;
                }
            }
        }

        .ant-table-row-level-0 {
            .ant-table-cell {
                background-color: var(--white-color) !important;
            }
        }

        .ant-table-thead {
            >tr {
                >th {
                    .b-t();
                }
            }
        }
    }

    .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
        .m-0();
    }

    .hide-header {
        .ant-table-thead {
            .hide();
        }
    }

    .ant-divider {
        .b-t();
    }
}

.tabelaNfe {
    .ant-table-small {
        .m-0();
    }
}

.ant-table-pagination {
    &.ant-pagination {
        margin: 8px 0 0 0 !important;
    }
}

.colorInp {
    .ant-input-suffix {
        .m-0();

        >span {
            .p-0();
            .b-0();
            .hg-100();
            width: 25px;
            margin-right: -7px;
        }
    }
}

.colCollapseCom {
    margin-top: 36px;

    .tb-head {
        .p-5();
        .f-500();
        color: @opacity-85;
        background-color: var(--gray-color);
    }

    .pad-list {
        .p-8();
    }

    .ant-collapse {
        .ant-table-pagination {
            &.ant-pagination {
                margin: 5px;
            }
        }

        .ant-collapse-item {
            .ant-collapse-content {
                .ant-collapse-content-box {
                    .p-0();
                    .b-0();

                    .ant-table-container {
                        .b-0();
                    }
                }
            }
        }
    }

    .ant-collapse {
        >.ant-collapse-item {
            >.ant-collapse-header {
                color: @text-color;
            }
        }
    }
}

.colCollapseTabela {
    .m-t-16();

    .ant-table-container {
        .b-0();
    }

    .ant-collapse-content-active {
        .m-t-0();
    }

    .ant-collapse {
        .ant-collapse-item {
            .ant-collapse-content {
                .ant-collapse-content-box {
                    .p-0();
                    .b-0();

                    .ant-table-thead {
                        .hide();
                    }
                }
            }
        }

        >.ant-collapse-item {
            >.ant-collapse-header {
                color: @text-color;
                padding-left: 15px;

                .ant-collapse-extra {
                    float: inherit;
                }

                .ant-collapse-arrow {
                    left: 10px;
                }
            }
        }
    }

    .ant-table-tbody {
        >tr {
            >td {
                &.ant-table-cell {
                    padding: 4px 0px !important;
                }
            }

            &.ant-table-expanded-row {
                >td {
                    &.ant-table-cell {
                        .p-4-5();
                    }
                }
            }
        }
    }
}

.b-position {
    right: 0;
    top: -15px;
    position: absolute;
}

.imgEmpresa {
    .ant-upload-list-picture-card-container {
        width: 200px;
        height: 160px;
    }
}

.perfil {
    .ant-upload.ant-upload-select-picture-card {
        .w-100();
        .m-r-0();
        .over-div();
        .m-b-0() !important;
    }

    .avatar-uploader {
        img {
            .p-0();
        }
    }

    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
        .p-0();
    }

    .ant-upload-list-picture-card-container {
        .m-0();
        .w-100();
        .hg-100();
    }

    .ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload-list-picture .ant-upload-list-item {
        .m-0();
    }
}

.uP {
    .ant-upload {
        .pad-10();
    }

    .ant-upload.ant-upload-select {
        .w-100();
    }
}

.listUsers {
    .m-t-10();

    .ant-divider {
        .m-t-b-5();
    }
}

.colContas {
    .hg-100();

    .marcacao {
        top: 10px;
        z-index: 1;
        left: 12px;
        width: 18px;
        height: 18px;
        padding: 0 1px;
        position: absolute;
        border: 1px solid #cfcfcf;
    }

    .b-color-primary {
        border-radius: 0;

        button {
            .p-5-8();
        }
    }

    .list-contas {
        display: block;
        overflow-x: hidden;
        height: calc(100% - 70px);
        background-color: var(--gray-color);
        border: 1px solid @border-color-base;

        @media screen and (max-width: 992px) {
            height: calc(100% - 75px);
        }

        @media screen and (max-width: 767px) {
            height: calc(100% - 175px);
        }

        @media screen and (max-width: 572px) {
            height: calc(100% - 232px);
        }

        &::-webkit-scrollbar {
            width: 10px;
            background-color: #e4e4e4;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c7c7c7;
        }

        .boxInfo {
            padding: 9px;
            display: flex;
            .p-relative();
            justify-content: center;
            background-color: #f9f9f9;
            border-bottom: 1px solid @border-color-base;

            &:hover,
            &:active {
                .bg-white();
                cursor: pointer;
            }

            .marcacao {
                top: 18px;
                left: 8px;
            }
        }
    }
}

.cor {
    .p-r-l-8();
    background-color: #c7c7c7;
}

.scroller {
    .hg-100();
    overflow-x: hidden;

    @media screen and (max-width: 767px) {
        height: calc(100% - 100px);
    }

    @media screen and (max-width: 572px) {
        height: calc(100% - 157px);
    }

    &::-webkit-scrollbar {
        width: 15px;
        background-color: #e4e4e4;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c7c7c7;
    }

    .b-t-r-l-1 {
        top: 0;
        left: 0;
        z-index: 1;
        .bg-white();
        position: sticky;
    }

    .ant-table-tbody>tr>td.ant-table-cell {
        .p-0();
    }
}

.colUsers {
    .ant-card-body {
        padding: 8px 8px 0 8px;
    }

    .ant-card-cover {
        img {
            border-radius: 100%;
        }

        >* {
            .p-0();
            width: 62%;
            margin: 0 auto;
            margin-top: 15px;

            @media screen and (max-width: 765px) {
                width: 85%;
            }

            @media screen and (max-width: 590px) {
                width: 120px;
            }
        }
    }

    .ant-card-meta-title {
        .m-b-0();
    }

    .ant-card-meta-description {
        line-height: 15px;
    }
}

.tabelaPlano {
    .ant-table-thead {
        .hide();
    }

    .ant-divider {
        margin: 5px 0 0;
    }

    .ant-tag {
        .m-r-0();
    }
}

.colBandeiras {
    .ant-card {
        min-height: 200px;

        .logoBandeira {
            margin-top: -35px;

            img {
                .bg-white();
                width: 100px;
                border: 1px solid @border-color-base;
            }
        }

        .ant-divider {
            margin: 3px 0;
        }

        .colConfig {
            height: 154px;
            display: flex;
            align-items: center;
            justify-items: center;
            vertical-align: middle;

            .ant-row {
                .w-100();
            }
        }
    }

    .btEdit {
        top: 5px;
        right: 5px;
        position: absolute;
    }
}

.conciliacaoCartoes {
    .ant-card {
        cursor: auto;
        margin: 15px 0;

        .imgLogoListaBanco {
            .m-l-5();
        }
    }

    .ant-collapse-ghost {
        >.ant-collapse-item {
            >.ant-collapse-content {
                .m-t-10();

                .ant-collapse-content-box {
                    .p-0();
                }
            }
        }
    }

    .ant-collapse {
        >.ant-collapse-item {
            &.ant-collapse-no-arrow {
                .ant-collapse-header {
                    .p-0();
                }
            }

            >.ant-collapse-header {
                .ant-collapse-extra {
                    float: inherit;
                }
            }
        }

        .ant-collapse-content-box {
            .p-0();
            border-width: 0 0 0 0;
            background-color: transparent;
        }
    }
}

.colCheque {
    margin-bottom: 10px;

    .ant-input {
        border-width: 0 0 1px 0;

        &:hover {
            border-width: 0 0 1px 0;
        }
    }

    .imgCheque {
        width: 120px;

        img {
            .w-100();
        }
    }
}

.imgLogoListaBanco {
    width: 25px;
    padding: 1px;
    border: 1px solid @border-color-base;
}

.difTable {
    .ant-table-thead {
        .hide();
    }
}

.upload {
    .ant-upload-list {
        .m-t-16();

        .ant-upload-list-item {
            .m-t-0();
            height: inherit;
            border-bottom: 1px solid @border-color-base;

            .ant-upload-list-item-name {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            .anticon {
                top: 50%;
                margin-top: -5px;
            }

            .ant-upload-list-item-card-actions {
                top: 50%;
                margin-top: -9px;
            }

            .ant-upload-list-item-card-actions-btn {
                opacity: 1;
            }
        }
    }
}

.col-pre {
    .ant-form-item-control {
        flex: 100%;
        max-width: 100%;
    }
}

.ant-layout {
    background-color: var(--white-color) !important;
}

.ant-collapse {
    &.topDif {
        >.ant-collapse-item {
            >.ant-collapse-header {
                .p-0();
            }
        }

        .ant-collapse-extra {
            margin-top: -29px;

            >button {
                z-index: 2;
                .p-relative();
            }

            @media screen and (max-width: 992px) {
                .m-t-3();
            }

            @media screen and (max-width: 572px) {
                .w-100();
            }
        }
    }

    &.mt-coll {
        .topDif();

        .ant-collapse-extra {
            @media screen and (max-width: 1197px) {
                margin-top: -36px !important;
            }

            @media screen and (max-width: 992px) {
                margin-top: -48px !important;
            }

            @media screen and (max-width: 572px) {
                .m-t-0();
                .w-100();
            }
        }
    }

    &.topGrade {
        .topDif();

        @media screen and (max-width: 992px) {
            .ant-collapse-extra {
                margin-top: -32px;
            }
        }
    }
}

.ant-collapse-content-box {
    padding-bottom: 16px !important;
    background-color: var(--gray-color);
    border: 1px solid @border-color-base;

    .list {
        .p-8();
        .b-t();
    }
}

.ver-aling {
    .ant-table {
        table {
            tbody {
                >tr {
                    vertical-align: top;
                }
            }
        }
    }
}

.ant-table-container {
    border: 1px solid @border-color-base;
    border-bottom: 0;
}

.ant-collapse-content {
    .ant-table-tbody {
        .ant-tag {
            width: auto !important;

            b {
                width: auto !important;
            }
        }
    }
}

.w-75 {
    width: 75px !important;

    b {
        .w-100();
        .text-center();
    }
}

.App {
    .ant-table-tbody {
        >tr {
            >td {
                border-bottom: 1px solid @border-color-base;
            }
        }
    }

    .tabela {
        .ant-table-tbody {
            >tr {
                >td {
                    vertical-align: middle;
                }
            }
        }
    }

    .ant-table-thead {
        >tr {
            >th {
                .b-b-1();

                &.ant-table-cell {
                    .p-4-5();
                }
            }
        }
    }
}

.footer-drawer {
    right: 0;
    bottom: 0;
    .bg-white();
    position: fixed;
    width: calc(100% - 25%);

    @media screen and (max-width: 1200px) {
        width: calc(100% - 10%);
    }
}

.footer-drawer-pages {
    right: 0;
    .w-100();
    bottom: 0;
    .bg-white();
    position: absolute;
}

.switch {
    .ant-form-item {
        .p-relative();
    }

    .ant-form-item-label {
        top: 22px;
        z-index: 2;
        left: 35px;
        position: absolute;

        @media screen and (max-width: 992px) {
            top: 26px;
        }

        @media screen and (max-width: 767px) {
            top: 5px;
        }

        >label {
            height: inherit;
            line-height: normal;
        }
    }

    .ant-form-item-control {
        .p-relative();
        margin-top: 21px;

        @media screen and (max-width: 767px) {
            .m-t-0();
        }
    }
}

.switch-modal {
    .switch();

    @media screen and (max-width: 767px) {
        top: 20px;
    }

    @media screen and (max-width: 572px) {
        top: 5px;
    }
}

.i-button {
    button {
        .m-t-19();

        span {
            font-size: 20px !important;
        }

        &.ant-btn-sm {
            @media screen and (max-width: 992px) {
                height: 32px !important;
            }
        }
    }

    &.tt0 {
        button {
            @media screen and (max-width: 572px) {
                .m-t-0();
            }
        }
    }
}

.add-button {
    button {
        .m-t-19();

        width: 100%;

        span {
            font-size: 12px !important;
        }

        &.ant-btn-sm {
            @media screen and (max-width: 992px) {
                height: 32px !important;
            }
        }
    }

    &.tt0 {
        button {
            @media screen and (max-width: 572px) {
                .m-t-0();
            }
        }
    }
}

.CurrencyInput {
    .w-100();
    height: 24px;
    .text-right();
    .b-radius-2();
    color: inherit;
    font-weight: 700;
    box-shadow: none;
    border-width: 1px;
    padding: 1.6px 7px;
    font-family: Arial;
    border-style: solid;
    transition: all 0.3s linear;
    border-color: @border-color-base;

    &:hover {
        border-color: #1d6db3 !important;
    }

    &:focus {
        outline: 0;
        border-color: #1d6db3;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(0, 83, 166, 0.2);
    }
}

.fixed-pesquisa {
    top: 0;
    left: 0;
    .p-15();
    .w-100();
    z-index: 1;
    .bg-white();
    .text-center();
    position: fixed;
}

.bandeiras {
    .p-5();
    .h-auto();
    float: left;
    width: 120px;
    .text-center();
    font-weight: bold;
    border: 1px solid @border-color-base;

    >div {
        .w-100();
        .over-div();

        img {
            .w-100();
            .bg-white();
        }
    }
}

.radioImage {
    .h-auto();
    float: left;
    width: 120px;
    .text-center();
    font-weight: bold;
    .pad-10() !important;
    margin: 3px !important;

    >div {
        .w-100();

        .over-div img {
            .w-100();
            .bg-white();
        }
    }
}

.drawer-modulos {
    .bg-white();
    transform: translateY(0) !important;

    .ant-drawer-content-wrapper {
        .m-t-0();
        height: calc(100% - 0px) !important;

        @media screen and (max-width: 1200px) {
            .w-100();
        }
    }

    .ant-drawer-header {
        .ant-drawer-close {
            color: @text-color !important;
        }
    }
}

.fixed-left {
    top: 0;
    left: 0;
    position: fixed;
    width: 54.16666667%;

    @media screen and (max-width: 1200px) {
        width: 40%;
    }

    @media screen and (max-width: 990px) {
        .hide();
    }
}

.fixed-right {
    top: 0;
    right: 0;
    .hg-100();
    position: fixed;
    width: 45.83333333%;
    background-color: var(--white-color) !important;

    @media screen and (max-width: 1200px) {
        width: 60%;
    }

    @media screen and (max-width: 990px) {
        .w-100();
    }

    .ant-col-18 {
        @media screen and (max-width: 690px) {
            flex: 0 0 87.5%;
            max-width: 87.5%;
        }
    }

    .ant-input-prefix {
        margin-right: 8px;
    }

    .ant-input-affix-wrapper {
        padding: 5.6px 8px 5.6px 5px;
    }
}

.ant-drawer-content-wrapper {
    margin-top: 55px !important;
    height: calc(100% - 55px) !important;

    @media screen and (max-width: 1366px) {
        width: 70% !important;
    }

    @media screen and (max-width: 1200px) {
        width: 95% !important;
    }

    @media screen and (max-width: 992px) {
        .w-100();
    }
}

.col-iframe {
    .p-b-0();
    top: 70px;
    left: 211px;
    .over-div();
    max-width: 100%;
    position: fixed;
    height: calc(100% - 80px);
    width: calc(100% - 221px);

    >iframe {
        top: 0;
        .p-0();
        .m-0();
        left: 0;
        right: 0;
        .w-100();
        .hg-100();
        bottom: 0;
        z-index: 0;
        .over-div();
        border: none;
        position: absolute;

        >html {
            >body {
                background-color: var(--white-color) !important;

                >header {
                    .hide();
                }
            }
        }
    }
}

.servImpressao {
    .text-center();

    .ant-spin-sm .ant-spin-dot {
        font-size: 100px;
    }
}

.col-abertura {
    z-index: 10;
    right: 10px;
    width: 450px;
    bottom: -150%;
    position: fixed !important;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;

    &.minus {
        bottom: -327px;
    }

    &.open {
        bottom: 0;
    }

    .ant-card-head {
        color: var(--white-color);
        background-color: @primary-color;

        button {
            color: var(--white-color);
        }
    }

    .ant-upload {
        &.ant-upload-drag {
            p {
                &.ant-upload-drag-icon {
                    .m-b-0();
                }
            }
        }
    }

    .ant-card-body {
        .det {
            >span {
                display: block;
            }
        }
    }

    .ant-upload-span {
        justify-content: center;
    }

    .ant-upload-list-picture-container {
        .m-r-5();
        float: left;
        .text-center();
    }

    .ant-upload-list-item {
        float: left;
        width: 58px;
        height: 30px;
        margin-right: 3px;

        .ant-upload-list-item-info {
            border: 1px solid @border-color-base;
        }

        .ant-upload-text-icon {
            .hide();
        }

        .ant-upload-list-item-name {
            .p-0();
            .text-center();
            font-size: 11px;
        }
    }

    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
        .p-5();
        .h-auto();
    }

    .ant-upload-list-text .ant-upload-list-item-card-actions,
    .ant-upload-list-picture .ant-upload-list-item-card-actions {
        z-index: 1;
        top: -10px;
        right: -12px;
        border-radius: 50%;
        position: absolute;
        background-color: var(--dark-color);
        border: 1px solid @border-color-base;
    }

    .ant-upload-list-item-card-actions {
        .ant-btn-icon-only.ant-btn-sm {
            width: 20px;
        }

        .ant-upload-list-item-card-actions-btn {
            opacity: 1;
        }
    }

    .ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
        font-size: 22px;
    }

    .ant-upload-list-picture .ant-upload-list-item-thumbnail,
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
        .h-auto();
        width: auto;
        .line-height();
    }
}

.col-home {
    .m-t-10();
    padding-left: 2px;
    height: calc(100% - 20px);
    width: calc(100% - 322px);

    @media screen and (max-width: 690px) {
        width: calc(100% - 0px);
    }
}

.col-boas-vindas {
    left: 222px;
    bottom: 20px;
    position: absolute;
    padding-bottom: 10px;
    width: calc(100% - 571px);
    border-bottom: 1px solid @border-color-base;
}

.col-right-home {
    top: 65px;
    right: 10px;
    width: 310px;
    position: absolute;
    height: calc(100% - 75px);
    background-color: var(--gray-color);
    border: 1px solid @border-color-base;

    @media screen and (max-width: 692px) {
        .hide();
    }

    .ant-select {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                border: 1px solid transparent;
            }
        }
    }

    .ant-picker-calendar-header {

        .ant-picker-calendar-year-select,
        .ant-picker-calendar-month-select {
            min-width: auto;
        }
    }

    .f-50 {
        line-height: 43px;
    }

    .calendar-demo-card {
        .w-100();

        .ant-picker-calendar {
            border-bottom: 1px solid @border-color-base;
        }

        .ant-select,
        .ant-input-number {
            width: auto !important;
        }

        .ant-picker-calendar-header {
            justify-content: center;
        }

        .ant-picker-calendar {
            .ant-picker-panel {
                .ant-picker-body {
                    padding: 8px 0 0;
                }
            }
        }
    }
}

.col-analise {
    .ant-card-small {
        >.ant-card-body {
            .p-0();
        }
    }

    .ant-row {
        .m-0();
    }

    .ant-card {
        font-size: 10px;
    }
}

.lista-resumo {
    .ant-card-body {
        .pad-10();
    }

    .selectOrdemNota {
        top: 5px;
        .w-100();
        right: 8px;
        position: absolute;

        @media screen and (max-width: 992px) {
            top: auto;
            right: auto;
            .p-relative();
        }
    }
}

.desc {
    .ant-form-item {
        flex-direction: inherit;
        display: inherit;
    }

    .ant-form-item-label {
        flex-direction: column;
        display: flex;
        flex-flow: row wrap;
    }

    .ant-radio-group {
        .w-100();
    }

    .ant-radio-group-small {
        .ant-radio-button-wrapper {
            width: 50%;
            .text-center();
        }
    }
}

.footer {
    left: 0;
    .w-100();
    bottom: 0;
    .bg-white();
    position: fixed;
    padding-bottom: 20px;
}

.col-empresa {
    overflow-x: hidden;
    padding-bottom: 108px;
}

.faturar {
    .inpDis {
        .ant-input[disabled] {
            .p-0();
            .b-0();
            opacity: 1;
            color: black;
            font-size: 20px;
            cursor: initial;
            background-color: transparent;
        }
    }
}

.ant-radio-button-wrapper-checked {
    &.btn-red {
        border-color: var(--red-color) !important;
        background-color: var(--red-color) !important;

        &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
            background-color: var(--red-color) !important;
        }
    }
}

.modalPlanos {
    width: 50% !important;

    @media screen and (max-width: 1390px) {
        width: 70% !important;
    }

    @media screen and (max-width: 1090px) {
        width: 90% !important;
    }

    .planos {
        height: 414px;
        overflow-x: hidden;
        .b-t();
        .b-b-1();

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #e4e4e4;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c7c7c7;
        }

        .titlePlano {
            .txt-overflow();
            font-size: 15px;
            font-weight: 600;
            color: @primary-color;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

            &.title {
                .p-t-b-5();
                color: var(--white-color);
                background-color: @primary-color;
            }
        }

        .ant-btn-link {
            border-color: @primary-color;

            &:hover {
                border-color: #1d6db3;
                color: var(--white-color);
                background-color: #1d6db3;
            }
        }

        .ant-col-9,
        .ant-col-2,
        .ant-col-3 {
            .b-b-1();
            border-right: 1px solid @border-color-base;
        }

        .ant-col-9 {
            .p-5-8();
            .txt-overflow();
            border-left: 1px solid @border-color-base;

            &.hg35 {
                height: 34px;
            }
        }

        .ant-col-2,
        .ant-col-3 {
            .p-t-b-5();
            font-weight: bold;
        }

        .anticon-check {
            color: @primary-color;
        }

        .anticon-close {
            color: var(--red-color);
        }
    }
}

.modalPDF {
    width: 55% !important;

    @media screen and (max-width: 1390px) {
        width: 72% !important;
    }

    @media screen and (max-width: 1200px) {
        width: 95% !important;
    }
}

.col-importarElementos {
    .ant-input-search-small {
        .ant-input-search-button {
            height: 22px;
        }
    }
}

.ant-drawer-top {
    .ant-drawer-content-wrapper {
        .m-t-0();
        .w-100();
        .hg-100() !important;
    }
}

.inputNumber {
    .b-0 {
        .p-0();
        .b-0();
    }

    .rc-input-number-input {
        .w-100();
        .b-radius-2();
        padding: 1px 7px;
        border: 1px solid @border-color-base;

        &:hover {
            border: 1px solid #1d6db3;
        }
    }

    .rc-input-number-focused {
        border: 1px solid #1d6db3;
        box-shadow: 0 0 2px 2px #ccdced;
    }
}

.uP {
    .ant-upload-btn {
        .p-0();
    }
}

.plane {
    h2 {
        &.ant-typography {
            .m-b-0();
        }
    }

    &.ant-typography {
        .h2 {
            .m-b-0();
        }
    }
}

.card-dashboard {
    height: 350px;
    background-size: auto !important;
    background-repeat: no-repeat !important;
    background-position: right bottom !important;

    @media screen and (max-width: 1390px) {
        height: 305px;
        background-position: center bottom !important;
    }

    @media screen and (max-width: 1024px) {
        height: 222px;
    }
}

.col-charts {
    height: 350px;

    @media screen and (max-width: 1200px) {
        height: auto !important;
    }

    .ant-col-24 {
        .hg-100();
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

.row-charts {
    height: 350px;

    @media screen and (max-width: 1200px) {
        height: auto !important;
    }

    .ant-col-24 {
        .hg-100();
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

.dash {
    .ant-table-placeholder {
        .ant-table-cell {
            .ant-row {
                height: 312px;

                .ant-col-24 {
                    .hg-100();
                    justify-content: center;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.personalize {
    .hg-100();

    .ant-tabs {
        .hg-100();
        .over-div();
    }

    .ant-tabs-content {
        .hg-100();
    }

    .col-elementor {
        .m-t-8();
        overflow-x: hidden;
        height: calc(100% - 27px);

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--gray-color);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }
    }

    &.perso-cadastro {
        #manutencaoFormulario {
            .hg-100();
            .over-div();
        }

        .col-formulario-60 {
            >.ant-row {
                .hg-100();

                >.ant-col-24 {
                    .hg-100();
                }
            }
        }
    }
}

.col-formulario-60 {
    .hg-100();
    width: 60%;
    float: left;
    .p-relative();
    display: block;
    padding-right: 15px;
    border-right: 1px dashed @border-color-base;

    .ant-tag {
        .m-r-0();
    }
}

.col-formulario-40 {
    width: 40%;
    float: left;
    .p-relative();
    display: block;
    min-height: 100%;
    padding-left: 15px;
}

.col-linha-direita {
    top: 5px;
    right: 5px;
    position: absolute;

    @media screen and (max-width: 992px) {
        .ant-btn-circle {
            &.ant-btn-sm {
                width: 32px !important;
                padding: 5.6px 0 !important;
            }
        }
    }
}

.zIndex1 {
    z-index: 1000;

    .ant-descriptions-header {
        .m-b-0();
    }

    .ant-card-small {
        >.ant-card-body {
            .pad-10();
        }
    }

    .ant-descriptions-small {
        .ant-descriptions-row {

            >th,
            >td {
                padding-bottom: 2px;
            }
        }
    }
}

.rc-input-number-input {
    .w-100();
    outline: 0;
    height: 24px;
    .b-radius-2();
    padding: 0 7px;
    text-align: left;
    transition: all 0.3s linear;
    background-color: transparent;
    border: 1px solid @border-color-base;
    -moz-appearance: textfield !important;

    @media screen and (max-width: 992px) {
        height: 30px;
    }
}

#lpi_naocalculacomissao {
    margin-top: -6px;
}

.header-fixed {
    top: 0;
    left: 0;
    .w-100();
    .pad-10();
    z-index: 1;
    position: fixed;
    background-color: var(--white-color);
    border-bottom: 1px solid @border-color-base;
}

.p-t-90 {
    padding-top: 90px;
}

.t-mob {
    .m-t-19();

    @media screen and (max-width: 765px) {
        .m-t-5();
    }
}

.mt-form-dif {
    .m-t-3();

    @media screen and (max-width: 1600px) {
        .m-t-19();
    }

    @media screen and (max-width: 1200px) {
        .m-t-5();
    }
}

.mt-fild {
    .m-t-3();

    @media screen and (max-width: 1200px) {
        .m-t-19();
    }

    @media screen and (max-width: 765px) {
        .m-t-5();
    }
}

.t-mob-573 {
    .m-t-19();

    @media screen and (max-width: 572px) {
        .m-t-0();
    }
}

.btn-mob {
    .m-t-19();

    @media screen and (max-width: 1200px) {
        .m-t-5();
    }

    @media screen and (max-width: 992px) {
        .m-t-19();
    }

    @media screen and (max-width: 572px) {
        .m-t-5();
    }
}

.btn-b-mob {
    .m-t-19();

    @media screen and (max-width: 1200px) {
        .m-t-5();
    }

    @media screen and (max-width: 765px) {
        .m-t-19();
    }

    @media screen and (max-width: 572px) {
        .m-t-5();
    }
}

.btn-col {
    flex: 0 0 100%;
    max-width: 100%;

    @media screen and (max-width: 765px) {
        .m-t-0();
        flex: 0 0 100%;
        max-width: 100%;
    }

    @media screen and (max-width: 576px) {
        .ant-col {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.btn-p {
    top: 0;
    right: 0;
    position: absolute;
}

.rg-txt {
    .text-right();
}

.col-pagina-breadcrumb {
    .ant-breadcrumb {
        .w-100();

        span {
            line-height: 18px;
        }

        @media screen and (max-width: 990px) {
            padding-left: 22px;
        }
    }

    @media screen and (max-width: 650px) {
        .m-0();
        .w-100();

        .col-w-100 {
            .p-0();
            align-content: center;
            display: flex;
            flex-container: row;
            .text-right();
            .w-100();

            >.ant-row {
                >.ant-col {
                    .w-100();
                }
            }
        }

        .col-mob-100 {
            .p-0();
            .w-100();
            .text-right();

            .ant-row-end {
                .m-t-b-5();
            }
        }
    }
}

.col-cartoes-collapse {
    .ant-collapse-extra {
        .w-100();

        .ant-col {
            .w-100();
        }
    }
}

.rodape-login-copyright {
    right: 0;
    .w-100();
    z-index: 1;
    bottom: 15px;
    font-size: 11px;
    position: absolute;
}

.menu-invent {
    .m-t-8();

    @media screen and (max-width: 992px) {
        .m-t-8();
    }

    @media screen and (max-width: 572px) {
        .m-t-0();
    }

    .ant-radio-group {
        display: flex;
        flex: 0 0 100%;
        max-width: 100% !important;

        .ant-radio-button-wrapper {
            flex: 0 0 50%;
            max-width: 50%;
            .text-center();
        }
    }
}

.check-vigencia {
    @media screen and (min-width: 766px) {
        .m-t-19();
        .p-l-25();
    }

    @media screen and (max-width: 765px) {
        .m-t-0();
        .p-l-5();
    }
}

.col-check-top {
    .m-t-19();

    @media screen and (max-width: 1600px) {
        .m-t-0();
    }

    @media screen and (max-width: 1198px) {
        .m-t-19();
    }

    @media screen and (max-width: 765px) {
        .m-t-0();
    }
}

.check-top {
    @media screen and (min-width: 766px) {
        .m-t-19();
    }

    @media screen and (max-width: 765px) {
        .m-t-0();
    }
}

.labelInput {
    .ant-input {
        .f-18();

        .ant-input-sm {
            height: 28px;
        }
    }

    .ant-form {
        .ant-form-item {
            .ant-form-item-label {
                min-width: 122px;
                text-align: right;

                @media screen and (max-width: 572px) {
                    flex: 0 0 50%;
                    max-width: inherit;
                }

                label {
                    .f-14();
                    text-align: right;
                    padding-right: 5px;
                    vertical-align: middle;
                }
            }

            .ant-form-item-control {
                @media screen and (max-width: 572px) {
                    flex: 0 0 50%;
                    max-width: inherit;
                }
            }
        }
    }
}

.col-radio-button {
    .w-100();

    .ant-radio-button-wrapper {
        width: 50%;
        .text-center();
        display: inline-flex;
    }
}

.drawer-pagina-aberta {
    .ant-drawer-content-wrapper {
        width: 320px !important;

        @media screen and (max-width: 1366px) {
            width: 320px !important;
        }

        @media screen and (max-width: 572px) {
            .w-100();
        }
    }

}

@media screen and (max-width: 1200px) {
    .t-0 {
        .m-t-0();
    }

    .tm5 {
        .m-t-5();
    }
}

@media screen and (max-width: 1024px) {
    .mt-3 {
        .m-t-3();
    }
}

@media screen and (max-width: 992px) {
    .t0 {
        .m-t-0();
    }

    .ant-table {
        .ant-btn-icon-only {
            &.ant-btn-sm {
                height: 24px !important;
                padding: 1.6px 0 !important;

                &.h32 {
                    .hg-mobile();
                }
            }
        }

        .ant-btn-sm {
            height: 24px !important;
            padding: 1.6px 5px !important;
        }
    }

    .ant-btn-sm {
        .hg-mobile();
        padding: 5.6px 15px !important;

        &.emp {
            .p-0();
            .h-auto();
        }
    }

    .ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: 100% !important;
    }

    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
        line-height: 32px !important;
    }

    .ant-input-sm,
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker-small,
    .ant-input-number-sm input,
    .CurrencyInput {
        .hg-mobile();
    }

    .ant-select-selector {
        .h-auto();
        min-height: 32px;
    }

    .ant-input-number-input-wrap {
        input {
            height: 29px !important;
        }
    }

    .ant-form-small {
        .ant-form-item-control-input {
            min-height: 32px !important;
        }
    }

    .ant-radio-group-small {
        .ant-radio-button-wrapper {
            .hg-mobile();
            line-height: 32px !important;
        }
    }

    .ant-input-affix-wrapper-sm {
        padding: 0 7px !important;
    }

    .ant-select-multiple.ant-select-sm {
        .ant-select-selection-item {
            height: 22px !important;
            line-height: 18px !important;
        }
    }

    .ant-select-multiple {
        .ant-select-selection-item {
            margin-top: 2px !important;
            margin-bottom: 2px !important;
        }
    }

    .t-5 {
        .m-t-5();
    }

    .ant-input-search {
        .ant-input-group-addon {
            .ant-btn-sm {
                .p-0();
            }
        }
    }

    .rg-t {
        .text-right();
    }
}

@media screen and (max-width: 767px) {
    .hd-col {
        .hide();
    }

    .rg-txt-769 {
        .text-right();
    }

    .t3 {
        margin-top: 3px;
    }

    .t15 {
        .m-t-15();
    }

    .t19 {
        .m-t-19();
    }

    .ant-divider-inner-text {
        .p-0();
        .w-100();
        .text-left();
        white-space: normal;
    }
}

@media screen and (max-width: 690px) {
    .tabela {
        >.ant-table-wrapper {
            overflow: hidden;
        }

        .ant-table-cell-fix-left {
            position: inherit !important;
        }

        .ant-table-thead>tr>th,
        .ant-table-tbody>tr>td,
        .ant-table tfoot>tr>th,
        .ant-table tfoot>tr>td {
            line-height: normal;
        }
    }
}

@media screen and (max-width: 572px) {

    .rg-txt-769,
    .rg-txt {
        .text-left();
    }

    .tt-5 {
        .m-t-5();
    }

    .tt-8 {
        .m-t-8();
    }

    .justify-c-end {
        justify-content: end !important;
    }

    .tt0 {
        .m-t-0();
    }
}

.txtEstoqueAzul {
    color: rgb(6, 112, 250) !important;
    font-weight: bold !important;
}

.txtEstoqueVermelho {
    color: rgb(255, 51, 0) !important;
    font-weight: bold !important;
}

.imgFilial {
    max-width: 100%;
    max-height: 100%;
    width: 150px;
    height: 150px;
}

.buttonUpload {
    margin-top: 20px;
    background: #f5f5f5;
    padding: 15px;
    transition: border-color 0.4s;
}
.mainShopingCart{
    display: flex;
}

.shopingCart {
    width: 50%;
    padding: 10px;
    height: 100%;
    background-color: beige;
    border-radius: 12px;
    margin: 20px 10px;
}

.FastShoppingCart {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
    padding: 6px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 12px #0000000f;
    margin-bottom: 18px;

    .descPeca {
        grid-column-start: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        display: grid;
        align-items: center;
    }

    .codPeca, .qtdItem {
        display: grid;
        grid-row-start: 1;
        grid-row-end: 3;
        align-items: center;
        justify-items: center;
    }
    .qtdItem {
        grid-column-start: 3;

        a {
            font-size: 16px;
            margin: 10px;
        }
    }
    .qtdItem, .valuePeca {
        p {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: inherit;

            @media (max-width: 1600px) {
                font-size: 15px;
            }
        }
    }
    .valuePeca {
        grid-column-start: 4;
        grid-row-start: 1;
        font-size: 16px;
        font-weight: bold;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-row-end: 3;
    }
}

.titleCart, .titleRevenue {
    font-size: 20px;
    font-weight: 500;

    svg {
        color: var(--gestor-color);
    }
    
}

.deletePeca {
    color: var(--gestor-color);
    display: grid;
    grid-row-start: 2;
    justify-content: center;
    align-items: center;
    justify-items: center;
    grid-row-end: 3;
}

.buttonsMocalPeca {
    display: flex;
    justify-content: space-between;

    button{
        border: none;
        color: white;
        font-size: 18px;
        height: 100%;
        border-radius: 12px;
        width: 40%;
    }

    .btnFecharModal {
        background-color: var(--gestor-color);
        font-size: 16px;
        &:hover {
            background-color: #003e7c;
        }
    }
    
    .btnAddCart {
            background-color: rgb(89, 192, 11);
            &:hover {
                background-color: rgb(71, 153, 8);
                color: white;
            }
    }

}

.totalRevenue {
    position: fixed;
    left: 50%;
    width: 42%;
    top: 6%;
    transform: translateX(16%);

    
    @media (max-width: 1600px) {
        top: 8%;
    }
    
    .containerRevenue{
        margin-top: 25px;
        height: 15vh;
        padding: 10px;
        background-color: (var(--gestor-color));
        border-radius: 12px;
        margin: 20px 10px;
        text-align: center;
        color: white;
        h1 {
            text-align: left;
            color: white;
        }
    }

    .valueRevenue {
        color: white;
        font-size: 32px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-size: 16px;
            margin-bottom: inherit;
            margin-right: 5px;
            font-weight: 500;
        }
    }

    .buttonContinue, .buttonFinish {
        height: auto;
        border: none;
        display: inline-block;
        color: white;
        border-radius: 15px;
        width: 30%;
        margin: 20px 10px;
        font-weight: 500;
        font-size: 16px;
        padding: 2%;

        @media (max-width: 1600px) {
            font-size: 14px;
        }

    }
    .buttonContinue {
        background-color: var(--gestor-color);
        
        &:hover {
            background-color: #003e7c;
            transition: 0.5s all ease;
            color: white;
        }
    }
    .buttonFinish {
        background-color: #00a645;
        font-size: 20px;
        &:hover {
            background-color: #008537;
            transition: 0.5s all ease;
            color: white;
        }
    }
}
.disabled-row {
    opacity: 0.5;
    pointer-events: none;
}
.mapa-brasil {
    position: relative;
    width: 630px;
    height: 630px;
    background-image: url('../../assets/mapa-brasil.png');
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
}
.estado {
    position: absolute;
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    color: #00000071;
    font-weight: 900;
    transition: background-color 0.3s;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.estado:hover {
    background-color: rgba(255, 255, 255, 0.6);
}
.estado img {
    position: absolute;
    margin-left: 40px;
    width: 30px;
    height: 30px;
}
.estado-selecionado {
    color: #000;
}

#ac { top: 220px; left: 75px; width: 30px; height: 30px; }
#al { top: 217px; left: 575px; width: 30px; height: 30px; }
#ap { top: 55px; left: 337px; width: 30px; height: 30px; }
#am { top: 115px; left: 135px; width: 70px; height: 70px; }
#ba { top: 240px; left: 475px; width: 60px; height: 60px; }
#ce { top: 145px; left: 520px; width: 30px; height: 30px; }
#es { top: 365px; left: 510px; width: 30px; height: 30px; }
#go { top: 320px; left: 360px; width: 40px; height: 40px; }
#ma { top: 145px; left: 430px; width: 40px; height: 40px; }
#mt { top: 250px; left: 265px; width: 70px; height: 70px; }
#ms { top: 375px; left: 290px; width: 40px; height: 40px; }
#mg { top: 330px; left: 440px; width: 60px; height: 60px; }
#pa { top: 125px; left: 300px; width: 70px; height: 70px; }
#pb { top: 177px; left: 577px; width: 30px; height: 30px; }
#pr { top: 440px; left: 335px; width: 40px; height: 40px; }
#pi { top: 190px; left: 480px; width: 30px; height: 30px; }
#pe { top: 196px; left: 580px; width: 30px; height: 30px; }
#rj { top: 415px; left: 483px; width: 30px; height: 30px; }
#rn { top: 155px; left: 570px; width: 30px; height: 30px; }
#rs { top: 530px; left: 305px; width: 40px; height: 40px; }
#ro { top: 230px; left: 165px; width: 40px; height: 40px; }
#rr { top: 40px; left: 200px; width: 30px; height: 30px; }
#sc { top: 495px; left: 365px; width: 30px; height: 30px; }
#sp { top: 410px; left: 380px; width: 30px; height: 30px; }
#se { top: 232px; left: 556px; width: 30px; height: 30px; }
#to { top: 225px; left: 385px; width: 40px; height: 40px; }
#df { top: 295px; left: 400px; width: 30px; height: 30px; }

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}